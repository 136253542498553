import { useAppDisplayModeContext } from '@archipro-design/aria';
import { useMatches } from '@remix-run/react';
import type { ProfessionalBannersData } from '@modules/professional/page/OverviewPage';

export const useCurrentTabBannerImage = (
    banners?: ProfessionalBannersData,
    squareBanner?: boolean
): string | null => {
    const { mobile: isMobile } = useAppDisplayModeContext();
    const matches = useMatches();
    const currentRoute = matches[matches.length - 1]!.pathname;
    const currentTabPath =
        currentRoute
            .split('/')
            .filter((path) => path)
            .pop() ?? '';

    if (!banners || banners.length === 0) {
        return null;
    }

    const isAboutSegment = currentTabPath === 'info';

    // pick up the correct banner image for current segment
    let banner;
    if (isAboutSegment) {
        banner = banners.find((b) => b.bannerSegment === 'ABOUTUS');
    }
    if (!banner) {
        banner = banners.find((b) => !b.bannerSegment);
    }
    if (!banner) {
        banner = banners[0] ?? null;
    }
    if (!banner) {
        return null;
    }

    const responsiveImage = squareBanner
        ? banner?.libraryImageFile?.DetailPageSegmentBanner
        : banner?.libraryImageFile?.DetailBannerStandard;

    // return the correct responsive image based on current device
    return (
        (isMobile ? responsiveImage?.Mobile : responsiveImage?.Desktop) ?? null
    );
};
